import { PrivateWindow, PrivateAndroidInterface, PrivateAndroidToJsInterface, CallbackId, PrintTextPayload } from './android-kassa-private'
import * as tidyPay  from './tidypay'

export { getApp, type Done, done }

const UUIDCharSet = '23456789abcdefghijkmnpqrstuvwxyz'

type Done = object

class LiveDone implements Done {
  toString(): string {
    return "Done"
  }
}

const done: Done = new LiveDone()

function getWindow(): Window & PrivateWindow {
  const w: unknown = window;
  return w as PrivateWindow
}

function embeddedInAndroidApp(): boolean {
  return !!getWindow().__android_app__
}

function generateCallbackId(): CallbackId {
  function generateUniqueString(length: number): string {
    // noinspection SpellCheckingInspection
    const charSetLength = UUIDCharSet.length

    let result = ''

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charSetLength)
      result += UUIDCharSet.charAt(randomIndex)
    }

    return result
  }

  return `cb-${ generateUniqueString(6) }`
}

type PrintTextArgs = {
  size?:      number,
  bold?:      boolean,
  underline?: boolean ,
}

function printTextArgsToPayload(args: PrintTextArgs): PrintTextPayload {
  const payload: PrintTextPayload = {
    size:      args.size      ?? null,
    bold:      args.bold      ?? false,
    underline: args.underline ?? false,
  }

  return payload
}

const defaultPrintTextArgs: PrintTextArgs = {
}

interface AndroidApp {
  ping(): Promise<Done>

  feed(): Promise<Done>
  printBitmapDataUrl(dataUrl: string): Promise<Done>

  printText(text: string, args?: PrintTextArgs): Promise<Done>
  tidyPayPerform(args: tidyPay.TidyPayPerformParams): Promise<Done>

  alignLeft(): Promise<Done>
  alignCenter(): Promise<Done>
  alignRight(): Promise<Done>

  build(): Builder
  test(): Promise<Done>
}

class AndroidAppImpl implements AndroidApp {
  private appInterface: PrivateAndroidInterface
  private appToJs:      PrivateAndroidToJsInterface

  private constructor(instance: PrivateAndroidInterface, appToJs: PrivateAndroidToJsInterface) {
    this.appInterface = instance
    this.appToJs = appToJs
  }

  public static getOrCreate(): AndroidApp | null {
    const instance = getWindow().__android_app__

    if (! instance) {
      return null
    } else {
      return new AndroidAppImpl(instance, getOrCreateGlobalAppToJsInterface())
    }
  }

  build(): LiveBuilder {
    return new LiveBuilder(this)
  }

  feed(): Promise<Done> {
    return this.callAsync((a) => (cb) => a.feed(cb));
  }

  ping(): Promise<Done> {
    return this.callAsync((a) => (cb) => a.ping(cb));
  }

  test(): Promise<Done> {
    // const testOpts: PrivateTestOpts = {
    //   id:   1,
    //   b:    true,
    //   i:    false,
    //   u:    true,
    //   size: null
    // };

    // const json = JSON.stringify(testOpts);
    return this.callAsync((a) => (cb) => a.test(cb, null));
  }

  printBitmapDataUrl(dataUrl: string): Promise<Done> {
    return this.callAsync((a) => (cb) => a.printBitmapDataUrl(cb, dataUrl));
  }

  printText(text: string, args?: PrintTextArgs): Promise<Done> {
    const effectiveArgs: PrintTextArgs = { ...defaultPrintTextArgs, ...args }
    const payloadJson = JSON.stringify(printTextArgsToPayload(effectiveArgs))

    return this.callAsync((a) => (cb) => a.printText(cb, text, payloadJson));
  }

  alignLeft() {
    return this.callAsync((a) => (cb) => a.alignLeft(cb));
  }

  alignCenter(): Promise<object> {
    return this.callAsync((a) => (cb) => a.alignCenter(cb));
  }

  alignRight() {
    return this.callAsync((a) => (cb) => a.alignRight(cb));
  }

  tidyPayPerform(params: tidyPay.TidyPayPerformParams): Promise<object> {
    const payloadJson = JSON.stringify(tidyPay.serializeParams(params))

    return new Promise<object>((resolve, reject) => {
      const callbackId = generateCallbackId()

      this.appToJs.register1(
        callbackId,
        (x)     => resolve(x),
        (error) => reject(new Error(error))
      )

      this.appInterface.tidyPayPerform(
        callbackId,
        payloadJson
      );
    })
  }

  private callAsync(fn: (x: PrivateAndroidInterface) => (cb: CallbackId) => void): Promise<Done> {
    return new Promise<void>((resolve, reject) => {
      const callbackId = generateCallbackId()

      this.appToJs.register(
        callbackId,
        ()      => resolve(),
        (error) => reject(new Error(error))
      )

      fn(this.appInterface)(callbackId)
    }).then(() => done);
  }
}

declare global {
  interface Window {
    __get_android__: () => AndroidApp | null;
    __android__:           AndroidApp | null;
  }
}

function getOrCreateGlobalAppToJsInterface(): PrivateAndroidToJsInterface {
  const w = getWindow()

  if (! w.__android_app_callbacks__) {
    w.__android_app_callbacks__ = new DefaultAppToJsInterface
  }

  return w.__android_app_callbacks__
}

function getApp(): AndroidApp | null {
  if (embeddedInAndroidApp()) {
    return AndroidAppImpl.getOrCreate()
  } else {
    return null
  }
}

type Callback = {
  onSuccess: () => void,
  onError:   (error: string) => void
}

type Callback1 = {
  onSuccess: (decodedPayload: object) => void,
  onError:   (jsonPayload: string) => void
}

class DefaultAppToJsInterface implements PrivateAndroidToJsInterface {
  private callbacks:  Map<CallbackId, [Callback]>  = new Map()
  private callbacks1: Map<CallbackId, [Callback1]> = new Map()

  register(id: CallbackId, onSuccess: () => void, onError: (error: string) => void): void {
    const existing = this.callbacks.get(id)

    if (existing) {
      existing.push({ onSuccess, onError })
    } else {
      this.callbacks.set(id, [{ onSuccess, onError }])
    }
  }

  register1(id: CallbackId, onSuccess: (arg0: object) => void, onError: (error: string) => void): void {
    const existing = this.callbacks1.get(id)

    if (existing) {
      existing.push({ onSuccess, onError })
    } else {
      this.callbacks1.set(id, [{ onSuccess, onError }])
    }
  }

  onSuccess(id: CallbackId): void {
    const callbacks = this.callbacks.get(id) || []

    for (const callback of callbacks) {
      callback.onSuccess()
    }

    this.callbacks.delete(id)
  }

  onSuccess1(id: string, jsonPayload: string): void {
    const decodedPayload = JSON.parse(jsonPayload);
    console.log("[andr] onSuccess1", id, JSON.stringify(decodedPayload, null, 2));

    const callbacks1 = this.callbacks1.get(id) || []

    for (const callback1 of callbacks1) {
      callback1.onSuccess(decodedPayload)
    }

    this.callbacks1.delete(id)
  }

  onError(id: CallbackId, message: string): void {
    const callbacks = this.callbacks.get(id) || []

    for (const callback of callbacks) {
      callback.onError(message)
    }

    this.callbacks.delete(id)
  }
}

type FeedCommand        = { 't': 'feed' }
type PrintTextCommand   = { 't': 'print_text', 'text': string, 'args'?: PrintTextArgs }
type PrintBitmapCommand = { 't': 'print_bitmap', 'data_url': string }
type AlignLeft          = { 't': 'align_left' }
type AlignCenter        = { 't': 'align_center' }
type AlignRight         = { 't': 'align_right' }

type Command = FeedCommand | PrintTextCommand | PrintBitmapCommand | AlignLeft | AlignCenter | AlignRight

interface Builder {
  addAlignLeft(): Builder
  addAlignCenter(): Builder
  addAlignRight(): Builder

  addFeed(): Builder

  addPrintText(text: string, args?: PrintTextArgs): Builder
  addPrintBitmapDataUrl(dataUrl: string): Builder

  run(): Promise<void>
}

class LiveBuilder implements Builder {
  private app: AndroidApp
  private commands: Command[] = []

  constructor(app: AndroidApp) {
    this.app = app
  }

  addFeed(): LiveBuilder {
    this.commands.push({ 't': 'feed' })
    return this
  }

  addPrintText(text: string, args?: PrintTextArgs): LiveBuilder {
    this.commands.push({ 't': 'print_text', text, 'args': args })
    return this
  }

  addAlignLeft(): LiveBuilder {
    this.commands.push({ 't': 'align_left' })
    return this
  }

  addAlignCenter(): LiveBuilder {
    this.commands.push({ 't': 'align_center' })
    return this
  }

  addAlignRight(): LiveBuilder {
    this.commands.push({ 't': 'align_right' })
    return this
  }

  addPrintBitmapDataUrl(dataUrl: string): LiveBuilder {
    this.commands.push({ 't': 'print_bitmap', data_url: dataUrl })
    return this
  }

  run(): Promise<void> {
    const app      = this.app
    const commands = JSON.parse(JSON.stringify(this.commands)) as Command[]

    async function pullNext(commands: Command[]): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        const nextCommand = commands.shift()

        if (! nextCommand) {
          resolve(undefined);
        } else {
          switch (nextCommand.t) {
            case 'feed':
              app
                .feed()
                .then(() => pullNext(commands))
                .then(resolve)
                .catch(reject)

              break

            case 'print_text':
              app
                .printText(nextCommand.text, nextCommand.args)
                .then(() => pullNext(commands))
                .then(resolve).catch(reject)

              break
            case 'print_bitmap':
              app
                .printBitmapDataUrl(nextCommand.data_url)
                .then(() => pullNext(commands))
                .then(resolve)
                .catch(reject)

              break
            case 'align_left':
              app
                .alignLeft()
                .then(() => pullNext(commands))
                .then(resolve)
                .catch(reject)

              break
            case 'align_center':
              app
                .alignCenter()
                .then(() => pullNext(commands))
                .then(resolve)
                .catch(reject)

              break
            case 'align_right':
              app
                .alignRight()
                .then(() => pullNext(commands))
                .then(resolve)
                .catch(reject)

              break
          }
        }
      })
    }

    return pullNext(commands)
  }
}

window.__get_android__ = getApp
window.__android__     = getApp()
