export type TidyPayPerformParams = {
  requestType:      string,
  accountId:        string
  terminalId:       string,
  amount:           number,
  transactionCode:  string,
  receiptMode:     string
}

export type TidyPayPerformParamsPayload = {
  request_type:     string,
  account_id:       string
  terminal_id:      string,
  amount:           number,
  transaction_code: string,
  receipt_mode:     string
}

export function serializeParams(params: TidyPayPerformParams): TidyPayPerformParamsPayload {
  return {
    request_type:     params.requestType,
    account_id:       params.accountId,
    terminal_id:      params.terminalId,
    amount:           params.amount,
    transaction_code: params.transactionCode,
    receipt_mode:     params.receiptMode
  }
}
